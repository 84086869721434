
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StatusPin',
  props: {
    state: String,
    title: String,
  },
  methods: {
    style() {
      if (this.state === 'done') return 'text-primary border-primary bg-primary';
      if (this.state === 'inprogress') return 'text-blue-500 border-blue-400 bg-blue-400';
      if (this.state === 'exported') return 'text-purple-500 border-purple-400 bg-purple-400';
      if (this.state === 'completed') return 'text-yellow-500 border-yellow-400 bg-yellow-400';

      return 'text-gray-500 border-gray-400 bg-gray-400';
    },
    icon() {
      if (this.state === 'done') return '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle class="path circle stroke-current text-primary" fill="none" stroke-width="10" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/><polyline class="path check stroke-current text-primary" fill="none" stroke-width="10" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/></svg>';
      if (this.state === 'inprogress') return '<svg class="fill-current" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><path d="M16,1C7.7157202,1,1,7.7157202,1,16s6.7157202,15,15,15s15-6.7157192,15-15S24.2842808,1,16,1z M16,27.7857151    C9.4909277,27.7857151,4.2142859,22.5090733,4.2142859,16S9.4909277,4.2142859,16,4.2142859S27.7857151,9.4909277,27.7857151,16    S22.5090733,27.7857151,16,27.7857151z"/></g><g><path d="M22.5761719,22.1396484c-0.2148438,0-0.4316406-0.0693359-0.6152344-0.2119141l-6.5771484-5.1396484    C15.1416016,16.5986328,15,16.3076172,15,16V9.4228516c0-0.5522461,0.4472656-1,1-1s1,0.4477539,1,1v6.0893555    l6.1933594,4.8393555c0.4345703,0.340332,0.5117188,0.96875,0.171875,1.4038086    C23.1679688,22.0078125,22.8740234,22.1396484,22.5761719,22.1396484z"/></g></g></svg>';
      if (this.state === 'exported') return '<svg class="stroke-current" id="Layer_1" style="enable-background:new 0 0 30.2 30.1;" version="1.1" viewBox="0 0 30.2 30.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="none" d="M2.1,14.6C8.9,12,28.5,4,28.5,4l-3.9,22.6c-0.2,1.1-1.5,1.5-2.3,0.8l-6.1-5.1l-4.3,4l0.7-6.7l13-12.3l-16,10  l1,5.7l-3.3-5.3l-5-1.6C1.5,15.8,1.4,14.8,2.1,14.6z"/></svg>';
      if (this.state === 'completed') return '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle class="path circle stroke-current text-yellow-500" fill="none" stroke-width="10" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/><polyline class="path check stroke-current text-yellow-500" fill="none" stroke-width="10" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/></svg>';

      return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-current"><g id="Solid"><path d="M256,352c-3.252,9.918-14.97,18.725-32.711,24.246-45.054,14.02-72.928,34.02-78.326,55.754H367.037c-5.4-21.734-33.272-41.734-78.326-55.754C270.97,370.725,259.252,361.918,256,352Z"/><path d="M160.1,128c13.43,25.58,34.93,48.48,63.19,66.55A72.525,72.525,0,0,1,256,244.36a72.525,72.525,0,0,1,32.71-49.81c28.26-18.07,49.76-40.97,63.19-66.55Z"/><path d="M305.949,221.507C363.206,184.9,400,127.959,400,64h0a16,16,0,0,0,16-16V24H96V47a16.985,16.985,0,0,0,16,16.949V64c0,63.959,36.794,120.9,94.051,157.507,25.159,16.086,25.159,52.9,0,68.986C148.794,327.1,112,384.041,112,448v.051A16.985,16.985,0,0,0,96,465v23H416V464a16,16,0,0,0-16-16h0c0-63.959-36.794-120.9-94.051-157.507C280.79,274.407,280.79,237.593,305.949,221.507ZM271.08,256a56.68,56.68,0,0,0,26.25,47.973C352.41,339.188,384,391.685,384,448H128c0-56.315,31.59-108.812,86.67-144.027a56.961,56.961,0,0,0,0-95.946C159.59,172.812,128,120.315,128,64H384c0,56.315-31.59,108.812-86.67,144.027A56.68,56.68,0,0,0,271.08,256Z"/><path d="M256,287.487a7.5,7.5,0,0,0,7.5-7.5V272a7.5,7.5,0,0,0-15,0v7.987A7.5,7.5,0,0,0,256,287.487Z"/><path d="M248.5,319.987a7.5,7.5,0,1,0,15,0V312a7.5,7.5,0,0,0-15,0Z"/></g></svg>';
    },
  },
});
