import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = ["textContent", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-row",
    id: _ctx.ui_id
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: _normalizeClass(["block border-gray-300 rounded-lg focus-within:border-primary py-3 xl:px-2 2xl:px-4 w-full text-sm appearance-none focus:outline-none bg-transparent", _ctx.className()]),
        textContent: _toDisplayString(_ctx.label),
        disabled: _ctx.isDisabled
      }, null, 10, _hoisted_3)
    ])
  ], 8, _hoisted_1))
}