
import { defineComponent } from 'vue';
import Segment from '@/types/Segment';
import globals from '../../helpers/globals';
import FormButton from '../../components/forms/Button.vue';
import StatusPin from '../../components/StatusPin.vue';
import File from '../../types/File';
import AccountDataService from '../../services/AccountDataService';
import SegmentDataHelper from '../../helpers/SegmentDataHelper';

export default defineComponent({
  name: 'CustomerPanel',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
    StatusPin,
  },
  data() {
    return {
      tab: 0,
      filtredFiles: [] as any,
    };
  },
  created() {
    const vm = this;

    /* LOAD DEPENDENCIES IF DONT ALREADY EXIST */
    this.$store.dispatch('isLoading', true);

    vm.init();

    this.loadSegments((data: any) => {
      vm.$store.state.segments.forEach((element: Segment) => {
        this.load(element.pid, (result: any) => {
          vm.filtredFiles[element.pid] = result.data;
        });
      });
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', false);
      this.$store.dispatch('toggleSideBar', false);
      this.$store.dispatch('toggleTopBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(segment: string, callback: any) {
      const token = this.getAccessToken();

      AccountDataService.getFiles(token, segment)
        .then((response: any) => {
          callback(response.data);
        });
    },
    loadSegments(callback: any) {
      SegmentDataHelper.prepareComponentData(
        this.$store,
        callback,
      );
    },
    activeTab(numberActive : any) {
      this.tab = numberActive;
    },
    goToNextStep(event: any) {
      /* FLAG STEP AS COMPLETED BEFORE MOVING TO NEXT SCREEN */
      // this.$store.dispatch('setHomeAsCompleted', true);

      /* MOVE TO NEXT SCREEN */
      event.preventDefault();

      this.$router.push({
        name: 'SegmentSelection',
        params: {},
      });
    },
    goToFile(file: File) {
      this.$router.push({
        name: 'CustomerFile',
        params: {
          file: file.pid,
        },
      });
    },
    fullProductName(file :File) {
      const financeType = file.simulation.finance_type.name;
      const product = file.simulation.product.fullname;

      return `${financeType} - ${product}`;
    },
    createdAt(file :File) {
      return `Créé le ${file.friendly_date}`;
    },
  },
});
