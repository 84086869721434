import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-none" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-row space-x-2 rounded-3xl py-2 px-3 border bg-opacity-10", _ctx.style()])
    }, [
      _createElementVNode("div", {
        class: "w-4",
        innerHTML: _ctx.icon()
      }, null, 8, _hoisted_2),
      _createElementVNode("div", {
        class: "text-xs block -mt-0",
        textContent: _toDisplayString(_ctx.title)
      }, null, 8, _hoisted_3)
    ], 2)
  ]))
}