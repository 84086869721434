
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormButton',
  props: {
    name: String,
    label: String,
    options: Array,
    isDisabled: Boolean,
    classes: null,
  },
  data() {
    return {
      ui_id: Math.random().toString(36).replace(/[0-9.]/g, ''),
      value: null,
    };
  },
  mounted() {
    const vm = this;

    const input = document.getElementById(this.ui_id) as HTMLElement;

    if (this.isDisabled && input !== null && input.parentElement !== null) {
      const parent = input.parentElement as HTMLElement;
      parent.classList.add('disabled');
    } else {
      const parent = input.parentElement as HTMLElement;
      parent.classList.remove('disabled');
    }
  },
  methods: {
    className(): string {
      return this.classes;
    },
  },
});
